.chart {
  width: 100%;
  background-color: #fff;

  &-title {
    padding: 8px 15px;
    color: #666;
    font-weight: 500;
    background-color: #f5f7fa;
    border: 1px solid #eee;
  }
  &-body {
    padding: 10px;
    &-pie {
      .recharts-wrapper {
        // width: 100% !important;

        // svg {
        //   width: 100%;
        //   height: 100%;
        // }

        .recharts-legend-wrapper {
          position: absolute;
          width: 300px !important;
          height: auto;
          right: 5px !important;
          top: 40% !important;
          left: unset !important;
          bottom: unset !important;
          @include customScrollSm;

          .recharts-default-legend {
            // display: block;
            position: absolute;
            top: 50%;
            left: 65%;
            transform: translate(-50%, -50%);
            min-width: 200px;
            text-align: left !important;
            // max-height: 130px;
            overflow-y: auto;

            .recharts-legend-item {
              svg {
                display: none !important;
              }
            }
          }
        }
      }
      .legend {
        &-label {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-bottom: 6px;

          &-name {
            color: #454545;
            font-size: 12px;
            margin-left: 6px;
          }
          &-value {
            font-size: 12px;
            font-weight: 600;
            color: #454545;
            margin-left: 6px;
          }
        }
        &-bg {
          width: 12px;
          height: 12px;
          border-radius: 50px;
          display: block;
          background-color: #666;
        }
      }
    }
  }
}
