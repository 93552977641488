.paginate {
    // margin-top:15px;
    // border:1px solid #dedede;
    border-top: 1px solid $table-border;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    // justify-content: space-between;
  
    .MuiFlatPagination-root {
      margin-top: unset;
      button {
        height: 25px;
        background-color: #f5f7fa;
        margin: 0 5px;
        color: #0a7ed3;
        span {
          line-height: 0;
          svg {
            font-size: 15px;
          }
        }
        &:first-child,
        &:last-child {
          color: #4e4e4e !important;
          background-color: transparent !important;
          font-size: 25px;
          padding: 5px !important;
          margin: 0 10px;
          border: none;
  
  
          &:hover{
            background-color: #efefef !important;
          }
  
          &.Mui-disabled {
            color: #afb3cc !important;
          }
        }
      }
      .MuiFlatPageButton-rootEnd {
        font-size: 22px;
      }
      .MuiButton-text {
        font-size: 13px;
        padding: 0 10px;
      }
      .MuiButton-textSecondary {
        background: #2680eb;
        color: #{$rigo-white-color};
        text-align: center;
      }
    }
    .MuiTablePagination-root {
      margin-top: unset;
      .MuiTablePagination-actions {
        display: none;
      }
      .MuiTablePagination-toolbar,.MuiTypography-body2 {
        font-size: 13px;
      }
      .MuiTypography-caption {
        font-size: 13px;
      }
    }
  }
  