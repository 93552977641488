.app {
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 14px;
  color: #444;
}
.app-container {
  @include containerWidth;
  padding: 58px 10px 10px 20px;
}

// .daraz-orders-actions {
//   & > div {
//     margin: 0 10px;
//   }
//   button {
//     max-height: 32px;
//   }
// }

.common {
  &-container {
    width: 100%;
    // padding: 5px 10px;
    // background-color: #f1f5f8;
    // background-color: #f1f5f8;
    // min-height: calc(100vh - 88px);
    height: 100%;
  }
  &-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    @include heading(3);
  }
  &-body {
    // padding: 0 0 0 10px;
  }
}
