.header{
    &-top{
        background-color: #fff;
        -webkit-box-shadow: 0 2px 2px -2px rgba(0,0,0,.5);
        box-shadow: 0 2px 2px -2px rgba(0,0,0,.5);
        min-height: 40px;
        // position: absolute;
        width: 100%;
        z-index: 1030;
        height: 44px;
        transition:all .2s;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding:0 10px;
    }
    &-sticky{
        .header-top{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }
    }
    &-col{
        h4{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        &-title{
            font-size: 20px;
            color:#111;
            font-weight: 500;
        }
        &-icon{
            font-size: 18px;
            margin-right: 5px;
        }
    }

}