.dashboard {
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #666;
    font-size: 16px;
    margin: 0 0 1rem 0;

    .link {
      color: #888;
      font-size: 14px;
      cursor: pointer;
    }
    .active {
      color: $link-color;
    }
  }
  &-title_bar {
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px 0;
    &-username {
      color: #006df9;
      font-size: large;
      font-weight: 500;
    }
    &-date {
      margin-right: 1rem;
      &-nepali {
        color: #006df9;
        margin-right: 1.5rem;
      }
    }
  }

  &-filter_bar {
    margin: 0 0 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .filter-item {
      padding: 8px;
      background-color: #fff;
      border-radius: 5px;
      margin: 0 0.6rem 0.5rem 0;
      box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
      cursor: pointer;
      text-decoration: none;

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
      }
    }
    .active {
      background-color: #006df9;
      color: #fff;
    }
  }

  &-counter_board {
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2px 10px;
      margin: 0 1rem 0 0;
      // text-decoration: none;
      .count {
        font-weight: 500;
        font-size: 16px;
      }
      .text {
        color: #444;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }

  &-diagram {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1.5rem;
  }
}
