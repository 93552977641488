.loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  //background-color: rgba($color-black, 0.8);
  z-index: 999999999999;
  // opacity: 0;
  // visibility: hidden;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  &-text {
    color: #{$rigo-white-color};
    padding: 1rem 0;
    letter-spacing: 1.5;
  }
}
.loader-icon {
  background: transparent !important;
  // width: 75px;
  // height: 75px;
  border-radius: 100%;
  border: 2px solid;
  border-color: #52a7fd;
  border-bottom-color: transparent;
  display: inline-block;
  -webkit-animation: spin 0.75s 0s infinite linear;
  animation: spin 0.75s 0s infinite linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  50% {
    -webkit-transform: rotate(180deg) scale(0.8);
    -ms-transform: rotate(180deg) scale(0.8);
    transform: rotate(180deg) scale(0.8);
  }

  100% {
    -webkit-transform: rotate(360deg) scale(1);
    -ms-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

.loader {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include overlayBefore(rgba(0, 0, 0, 0.2));
  }
  &-container {
    width: 130px;
    height: 130px;
    background: #{$rigo-white-color};
    z-index: 111;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  &-spinner {
    background: transparent !important;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 4px solid;
    border-color: #52a7fd;
    border-bottom-color: transparent;
    border-top-color: transparent;
    display: inline-block;
    -webkit-animation: spin 0.75s 0s infinite linear;
    animation: spin 0.75s 0s infinite linear;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  &-name {
    font-size: 20px;
    color: #7a7a7a;
    font-weight: 500;
    margin-top: 10px;

    span {
      animation: blink 1.4s infinite both;
      font-size: 30px;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.skeleton-loading {
  svg {
    // width: 100%;
  }
}

.skeleton {
  &-card {
    min-width: 350px;
    max-width: 100%;
    background-color: #{$rigo-white-color};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    padding: 15px;
    border-radius: 3px;
    margin-bottom: 15px;

    &__flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      .MuiSkeleton-root {
        margin-right: 10px;
      }
    }
    &__avatar {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 10px;

      .MuiSkeleton-root {
        margin-right: 10px;
      }
    }
    &__row {
      display: grid;
      //grid-template-columns: 40% 60%;
      margin-bottom: 10px;
    }
    &__label,
    &__value {
      width: 100%;
    }
    &-employee {
      width: 250px;
      margin: 10px 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-block {
      //width: 500px;
    }
    &-dashboard {
      width: 190px;
      height: 140px;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      &__header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }
      &__body {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
    &-travel {
      @include containerWidth;
      display: flex;
      flex-direction: column;
      padding: 0;

      &__header {
        padding: 10px 15px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        border-bottom: 1px solid #dfdfdf;
        &-col {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &:first-child {
            justify-content: flex-start;
            grid-column: 1 / 3;
          }
        }

        &-name {
          display: flex;
          flex-direction: row;
          align-items: center;

          .MuiSkeleton-circle {
            margin-right: 10px;
          }
        }
      }
      &__body {
        padding: 20px 20px 20px 50px;

        div {
          .MuiSkeleton-root {
            margin-bottom: 10px;
          }
        }
      }
      &__footer {
        background-color: #f5f7fa;
        padding: 10px 15px;

        .MuiSkeleton-circle {
          margin: 0 10px;
        }
      }
    }
  }

  &-table {
    @include containerWidth;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    background-color: #{$rigo-white-color};
    &__body {
    }
    &__row {
      padding: 10px;
      border-bottom: 1px solid #dfdfdf;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
    }
    &__col {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &:first-child {
        grid-column: 1 / 2 span;
        justify-content: flex-start;
      }
      &:nth-child(2) {
        grid-column: 3 / 2 span;
        justify-content: flex-start;
      }
      .flex {
        .MuiSkeleton-root {
          margin-left: 10px;
        }
      }
    }
    &__avatar {
      display: flex;
      flex-direction: row;
      align-items: center;
      .MuiSkeleton-circle {
        margin-right: 10px;
      }
    }
  }
}
.table-block {
  .MuiSkeleton-text {
    transform: unset;
  }
}
