.common-drop {
  position: relative;
  &.active {
    .common-drop__content {
      opacity: 1;
      visibility: visible;
      right: 0px;
      top: 25px;
      &::after {
        position: absolute;
        top: -8px;
        left: 85%;
      }
    }
  }

  &__content {
    @include dropDownContent;
    //   top: 42px;
    right: 0px;
  }
  &-list {
    list-style: none;
    width: 100%;
    &__item {
      width: 100%;
      padding: 5px 10px;
      line-height: 1.5;
      cursor: pointer;
      display: flex;
      z-index: 11;
      align-items: center;

      &.with-hr {
        border-top: 1px solid #efefef;
      }
      &:hover {
        background-color: lighten($color-primary-light-3, 10);
      }
    }
  }
  &-name {
    font-size: 18px;
    color: #777;
    font-weight: 400;
    margin-right: 10px;
  }
  &-down-caret {
    font-size: 22px;
    color: #666;
  }
}
