.breadcrumb_custom {
  display: flex;
  //   padding: 5px 0 10px 10px;
  &-back {
    margin-left: 10px;
    &-icon {
      margin-right: 5px;
      fill: #666;
      cursor: pointer;
    }
    svg {
      font-size: 16px;
    }
  }
  span {
    display: flex;
    align-items: center;
  }
  .module {
    font-size: 13px;
    color: #0052ab;
    cursor: pointer;
  }
  .path-name {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-left: 1.5rem;
  }
}
