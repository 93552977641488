.image-cropper {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .upload {
    &-section {
      width: 300px;
      height: 250px;
      text-align: center;
      border: 1px dashed #979797;
      border-radius: 8px;
      background: #fbfbfb;
      margin: 0 auto;
    }

    .ReactCrop {
      max-height: 100%;

      .recruit-picker {
        margin-left: unset !important;
        width: calc(100% - 225px);

        & > div {
          max-width: 100%;
          max-height: 100%;

          img {
            object-fit: contain;
            max-height: 100%;
          }
        }
      }
    }
    &-section__new {
      width: 390;
      height: 295;
      text-align: center;
      margin: 0 auto;
    }
    &-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      span {
        font-weight: 500;
        font-size: 16px;
        color: #999;

        &:not(:first-child) {
          margin-top: 8px;
        }

        &.text-blue {
          color: #{$color-blue};
        }
      }
    }
    &-img {
      position: relative;
      width: 150px;
      height: 150px;
      border: 1px solid #dfdfdf;
      border-radius: 100%;
    }
    &-img__new {
      position: relative;
      width: 200px;
      height: 200px;
      // border: 1px solid #dfdfdf;
      border-radius: 100%;
      margin-left: 50px;
    }
    &-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 30px !important;
      color: #8b959e !important;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        color: #{$color-red} !important;
      }
    }
    &-icon__new {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 25px !important;
      color: #8b959e !important;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        color: #{$color-red} !important;
      }
    }
  }
}

// .image-cropper {
//   height: 300px;
//   width: 100%;
//   // flex: 1;
//   // flex-direction: column;
//   background-color: #fff;
//   padding: 10px;
//   // position: absolute;
//   .reactEasyCrop_Container {
//     // position: unset !important;
//     .reactEasyCrop_Image {
//       // position: unset !important;
//     }
//     .reactEasyCrop_CropArea {
//       position: unset !important;
//       left: 50%;
//       // top: 10%;
//     }
//   }

//   &-upload {
//     flex: 1;
//     background-color: red;
//     z-index: 1000;
//   }
//   // &-preview {
//   //   flex: 1;
//   //   background-color: pink;
//   // }
// }
