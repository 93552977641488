.list-page {
  // width: 100%;
  // background-color: #f1f5f8;

  .button.with-icon {
    max-width: unset;
  }

  &-body {
    .search_filter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .filters {
        width: unset;
      }
      .button {
        min-width: 245px;
      }
    }
    // .btn-export-container {
    //   display: flex;
    //   flex-direction: row-reverse;
    //   align-items: center;
    //   button {
    //     height: 30px;
    //   }
    // }

    // .price_stock_update {
    //   .ag-theme-alpine .ag-cell {
    //     line-height: 20px !important;
    //     padding: 5px 16px;
    //   }

    //   .daraz {
    //     &-mrp {
    //       display: flex;
    //       justify-content: space-between;
    //     }

    //     &-sp {
    //       cursor: pointer;
    //     }
    //   }
    // }
  }

  .table {
    &-link_with_daraz {
      display: inline-flex;
      align-items: center;
      color: #1786ff;
      .text {
        margin: 0 8px;
        cursor: pointer;
      }
    }
    &-stock_item {
      color: #1786ff;

      &-add {
        display: flex;
        cursor: pointer;
        color: #1786ff;

        svg {
          margin-right: 0.75rem;
        }
      }
    }

    &-price_stock {
      &-section {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-data {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-sub_data {
          font-size: smaller;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #666;
        }
      }

      &-daraz_price {
        position: relative;
        color: $color-blue;
        cursor: pointer;
        // letter-spacing: 1px;
        // text-align: end;

        .value {
          width: 100%;
          display: inline-flex;
          justify-content: space-between;
          svg {
            font-size: 12px;
            margin-right: 5px;
          }
        }
        &:hover {
          font-weight: 600;
          text-decoration: underline;
        }

        .custom-popup {
          position: absolute;
          top: -1px;
          /* left: 50%; */
          z-index: 11111;
          background: #fff;
          padding: 2px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          margin-left: -15px;
          min-width: unset;
          // display: none;
          flex-direction: column;
          transition: all 0.2s;

          &-body {
            flex-grow: 1;

            .genericForm-group {
              margin-right: unset;
            }
          }
          &-open {
            display: flex;
            transform: scale(1);
            opacity: 1;
          }

          &-btn {
            padding: 5px 2px;
            &:hover {
              background-color: #ddd;
            }
          }
          // &-add {
          //   background-color: green;
          // }
          // &-remove {
          //   background-color: red;
          // }
        }
      }

      &-last_update {
        display: inline-flex;
        justify-content: center;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
}
