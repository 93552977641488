.asset {
  &-detail {
    &-table {
      width: 100%;
      margin-top: 1rem;
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }

      td,
      th {
        border: 1px solid #bbb;
        text-align: left;
        padding: 8px;
      }

      // tr:nth-child(even) {
      //   background-color: #dddddd;
      // }
    }
    &-note {
      margin: 1.2rem 0;
    }
    &-images {
      display: inline-flex;
      width: 100%;
      overflow: auto;
      overflow-y: auto;
      overflow-x: auto;

      &-image {
        margin: 0 1rem 1rem 0;
        max-width: 400px;
        max-height: 400px;
        width: 100%;
      }
    }
    &-comments {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      width: 60%;
      border-radius: 5px;
      padding: 5px;
      margin-bottom: 5px;
      border: 1px solid #ddd;
    }
  }
}
.office_location {
  &-add {
    display: flex;
    flex-direction: row;
    &-left {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 20px;
    }
    &-right {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 20px;
    }
  }
}

.group_subgroup_category {
  .subAccordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-detail {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .MuiAccordionSummary-content {
    // display: flex;

    .block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      &-name {
        width: 100%;
        margin-right: 15px;

        .MuiTypography-root {
          color: #3a3a3a;
          font-weight: 500;
        }
      }
      &-actions {
        display: flex;
        flex-direction: row;
        button {
          height: 30px;
        }
      }
    }
  }

  .MuiSvgIcon-root {
    fill: #777 !important;
    margin-right: 10px;
    font-size: 22px;
  }

  .MuiAccordionDetails-root {
    padding: 8px 5px 5px 20px;

    .sub-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 2px 0;

      &-name {
        width: 100%;
        margin-right: 15px;
        color: #5a5a5a;
      }
      &-description {
        width: 100%;
        margin-right: 15px;
        color: #267eda;
        margin-left: 20px;
      }
      &-actions {
        display: flex;
        flex-direction: row;
        button {
          height: 30px;
        }
      }
    }
  }

  .MuiCollapse-container {
    line-break: anywhere;
  }

  .MuiPaper-root .MuiAccordion-root {
    width: 100%;
  }
}

.table{
  &-addedOn {
    &-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 20px;
      &-data {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-sub_data {
        font-size: smaller;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #666;
      }
    }
  }
}

.assets-table{
  .ag-cell{
    // display:block;
    display: grid;
    place-items: center;
    line-height:1.5 !important;
    white-space: break-spaces;
  }
}