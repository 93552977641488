// Navigation
.header {
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #0068d4;
  padding: 0 1rem 0 1.5rem;
  position: fixed;
  z-index: 1111;

  &-title {
    min-width: 250px;
    font-size: 18px;
    color: #fff;
  }
  &-navigation {
    width: 100%;
  }
  .navbar {
    width: 100%;
    padding: 0 30px;

    height: 40px;
    &-list {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: row;
      height: 100%;
      // overflow: hidden;

      &__item {
        height: 100%;
        position: relative;
      }
      .dropdown {
        position: relative;
        width: unset !important;
        min-width: unset !important;
        max-width: unset !important;
        background: unset !important;
        box-shadow: unset !important;
        svg {
          font-size: 20px;
        }

        &:hover {
          .dropdown-content {
            display: block;
          }
        }
        &-content {
          display: none;
          width: 200px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
          position: absolute;
          bottom: -10px;
          left: 0;
        }
        &-lists {
          width: 100%;
          display: flex;
          list-style: none;
          flex-direction: column;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

          &__item {
            width: 100%;
            padding: 10px 20px;
            // text-align: center;
            cursor: pointer;
            background-color: #{$rigo-white-color};
            color: #444;
            font-size: 14px;
            font-weight: 400;

            &:not(:last-child) {
              border-bottom: 1px solid #c3c3c3;
            }

            &:hover {
              background-color: #efefef;
            }
          }
        }
      }
      &__links {
        height: 100%;
        padding: 15px 20px;
        font-size: 14px;
        color: #fafafa;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        cursor: pointer;

        &-icon {
          margin-right: 10px;
          svg {
            font-size: 20px;
          }
        }
        &:hover {
          background-color: lighten(#0052ab, 15);
        }

        &.active {
          background-color: #0052ab;
          color: #{$rigo-white-color};
        }
      }
    }
  }

  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    // border: 0.5px solid #eee;
    // border-radius: 5px;
    // &:hover {
    //   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // }

    &-drop {
      position: relative;
      &-item {
        &-version {
          text-align: center;
          padding-top: 5px;
          margin-top: 10px;
          border-top: 1px solid #66666629;
          color: #5d5d5d;
          font-size: 13px;
        }
        font-size: 14px;
        padding: 3px;
        margin-bottom: 5px;
        color: #666;
        svg {
          fill: #666;
          font-size: 16px;
        }

        &:hover {
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        }

        .logout {
          color: red;
          svg {
            fill: red;
          }
        }
      }

      &__active {
        .profile-drop__content {
          opacity: 1;
          visibility: visible;
          right: 0px;
          top: 45px;
          transform: translateY(0px);
          width: 190px;
          &::after {
            position: absolute;
            top: -8px;
            left: 85%;
          }
        }
      }

      &__content {
        @include dropDownContent;
        top: 48px;
        right: -0px;
        width: 210px;
        padding: 10px 15px;
        transition: all 0.2s linear;
        transform: translateY(15px);

        &::after {
          display: none;
          top: -13px;
          width: 25px;
          height: 25px;
          left: 83%;
        }
      }
    }

    &-image {
      border-radius: 50%;
      // border: 1px solid #c3c3c3;
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      img {
        max-width: unset !important;
        // width: calc(100% - 4px);
        // height: calc(100% - 4px);
        object-fit: cover;
      }
    }
    &-name {
      white-space: nowrap;
      font-size: 15px;
      color: #fff;
      font-weight: 400;
      margin-right: 10px;
    }
    &-down-caret {
      font-size: 20px;
      color: #fff;
      line-height: 0;
    }
  }
}

.navbar-dropdown {
  position: absolute;
  top: 100%;
  // right: 0;
  // max-width: 200px;
  box-sizing: border-box;
  // background-color: #0077f8;
  z-index: 11111;

  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
  &__item {
    width: 100%;
    // text-align: center;
    // overflow: hidden;
    border-bottom: 1px solid #{$rigo-white-color};
    height: 100%;

    // &:not(:last-child) {
    //   border-right: 1px solid #{$rigo-white-color};
    // }
  }
  &__link {
    // width: 70px;
    display: flex;
    padding: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover,
    &.active {
      background-color: #0052ab;
    }
  }
  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    img {
      width: 100%;
      height: 100%;
      filter: invert(1);
    }
    svg {
      fill: #fff;
      font-size: 18px;
    }
  }
  &__title {
    font-size: 14px;
    color: #{$rigo-white-color};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.drop-active {
  a {
    background-color: #0077f8;
  }
}

.dropdown {
  &-enter {
    top: 0;
    // width: 100%;
    // height: 45px;
    min-width: 120px;
    background-color: #0068d4;
    color: transparent;
    &-done {
      min-width: 120px;
      // width: 400px;
      // height: 60px;
    }
    &-active {
      top: 100%;
      // width: 400px;
      // height: 60px;
      min-width: 120px;
      color: transparent;
      background-color: #0077f8;
      transition: all 300ms;
    }
  }
  &-exit {
    top: 100%;
    // width: 400px;
    // height: 60px;
    min-width: 120px;
    color: transparent;
    background-color: #0077f8;
    &-done {
      top: 0;
      width: 100%;
      height: 45px;
      background-color: #0068d4;
      color: transparent;
    }
    &-active {
      top: 0;
      width: 100%;
      height: 45px;
      color: transparent;
      background-color: #0068d4;
      transition: all 300ms;

      ul {
        display: none;
      }
    }
  }
}
