.filters {
  width: 100%;
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // align-items: flex-end;

  &-grid {
    // max-width: 250px;
    min-width: 200px;
    color: $color-grey;
    margin: 10px 20px 0 0;
  }
  .button{
    margin-bottom: 10px;
  }
}
