.dropdown{
    width: auto;
    min-width: 150px;
    max-width: 220px;
    position: relative;
    background:#c3dbee;
    display: inline-block;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    z-index: 7;
    // overflow: hidden;

    &-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:10px;
        // border:1px solid #ccc;

    }
    &-left{
        font-size: .9rem;
        font-weight: 500;
        margin-right: 5px;

        @include respond(desktop){
            font-size: 1rem;
        }
        @include respond(med-desktop){
            font-size: 1.1rem;
        }
        @include respond(big-desktop){
            font-size: 1.2rem;
        }
        
    }
    &-title{
        flex-grow: 1;
        font-size: 1rem;
        color:#{$color-primary-dark};
        text-align: center;
        margin:0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include respond(desktop){
            font-size: .85rem;
        }
        @include respond(med-desktop){
            font-size: .925rem;
        }
        h3{
            font-weight: 500;
            @include respond(med-desktop){
                font-weight: 400;
            }
        }
    }
    &-right{
        font-size: 1.05rem;
        color:#646464;
        @include respond(desktop){
            font-size: 1.1rem;
        }
        @include respond(med-desktop){
            font-size: 1.2rem;
        }
        @include respond(big-desktop){
            font-size: 1.3rem;
        }
        
    }
    &-content{
        display: none;
        position: absolute;
        top:100%;
        left:0;

    }
    &-list{
        list-style: none;
        width: 230px;
        background-color: #e4eaf3;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);

        &__item{
            width: 100%;
            padding:10px;
            font-size: 1rem;
            color:#{$color-blue};
            display: flex;
            align-items: center;
            cursor: pointer;

            &.active-item{
                background-color:darken(#e4eaf3,10) ;
            }
            &.cancel{
                justify-content: center;
                border-bottom: 1px solid #fafafa;
                color: #555;
                font-size: 1.4rem;
                &:hover{
                    background: #{$color-red-fade};
                }
            }
            &:hover{
                background: darken(#e4eaf3,15);
            }

            &.with-hr{
                border-bottom: 1px solid #fafafa;
            }
        }
    }
    &-open{
        display: block;
    }

}

.drop-container{
    margin-right: 10px;
    width: 360px;

    @include respond(med-desktop){
        width: 380px;
    }
    @include respond(big-desktop){
        width: 400px;
    }
}