.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;

  &-container {
    width: 25%;
    max-width: 400px;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #eee;

    &:hover {
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
    }
  }

  &-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: larger;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    &-note {
      font-size: medium;
    }
  }
  &-body {
    margin: 3rem 0 2rem 0;

    &-button {
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-forget {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
