@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.daterangepicker {
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: $default-border-radius;
  border-width: 1px;
  border-style: solid;
  border-color: $rigo-input-border-color;
  background-color: $rigo-input-bg-color;
  align-items: center;
  border-radius: 4px;
  // background: #fff;
  padding: 8px;
  height: $input-height;
  width: 100%;

  .daterangepicker__icon {
    position: absolute !important;
    right: 0;
  }

  .placeholder {
    color: rgba(0, 0, 0, 0.54);
    font-size: inherit;
  }

  &.active {
    @include inputFocusStyle;
    .daterangepicker__range {
      display: block;
      margin: 0 0 20px 20px;
    }
    .daterangepicker__icon,
    .daterangepicker__date {
      color: lighten($btn-primary-color, 15);
    }
  }
  &__date {
    flex-grow: 1;
  }
  &__input {
    border: none;
    flex-grow: 1;
    height: 100%;
    font-size: 14px;
    height: 0;
    outline: none;
    &:focus {
      outline: none;
    }
  }
  &__date {
    font-size: 14px;
    color: #444;
    span {
      // margin: 5px 7px;
    }
  }
  &__range {
    display: none;
    position: absolute;
    top: 120%;
    left: 70% !important;
    transform: translateX(-50%);
    z-index: 1111;
    box-shadow: 0 6px 12px 1px rgba(0, 0, 0, 0.2);

    .rdrMonthAndYearWrapper {
      height: unset !important;
      padding: unset !important;
    }
    .rdr-DateRange {
      display: flex !important;
      flex-direction: row;

      .is-inRange {
        background-color: #2680eb !important;
        color: #fff !important;
      }
      .is-selected {
        background-color: #d64545 !important;
      }
    }
  }
  &__icon {
    width: 30px;
    margin: 0 5px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7c7c7c;
    font-size: 18px;
  }
}
