.organization {
  display: flex;
  flex-direction: column;
  width: 100%;
 box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  .row {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    width: 100%;
    padding: 2px 0;
    color: #5a5a5a;
  }

  .details {
    width: 100%;
    .department {
      width: 55%;
      margin-left: 1rem;
    }
    .actions {
      width: 225px;
    }
  }

  .title {
    color: #222;
  }

  .directorate {
    width: 25%;
    // margin-right: 15px;
  }

  .department {
    width: 42%;
  }

  .actions {
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: row;
    button {
      height: 30px;
    }
  }

  .MuiSvgIcon-root {
    fill: #777 !important;
    margin-right: 10px;
    font-size: 22px;
  }

  .MuiAccordionDetails-root {
    padding: 8px 5px 5px 20px;
  }

  .MuiCollapse-container {
    line-break: anywhere;
  }

  .MuiPaper-root .MuiAccordion-root {
    width: 100%;
  }
}
