
@keyframes fade-in{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

@keyframes fade-out{
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }

@keyframes scale-fade-in{
    0%{
        transform:  translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    100%{
        transform:  translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@keyframes scale-fade-out{
    0%{
        transform:  translate(-50%, -50%) scale(1);
        opacity: 0;
    }
    100%{
        transform:  translate(-50%, -50%) scale(.8);
        opacity: 1;
    }
}

$transition:all 240ms cubic-bezier(0, 0, 0.2, 1);
$transition2:all 200ms cubic-bezier(0, 0, 0.2, 1);
$animate:280ms cubic-bezier(0, 0, 0.2, 1) 0s 1 normal both running fade-in;
$animate3:280ms cubic-bezier(0, 0, 0.2, 1) 0s 1 normal both running fade-out;
$animate2:240ms cubic-bezier(0, 0, 0.2, 1) 0s 1 normal both running scale-fade-in;
$animate4:240ms cubic-bezier(0, 0, 0.2, 1) 0s 1 normal both running scale-fade-out;

@mixin modelContent($show){
    @if $show == show{
        .modal__content{
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
    @if $show == hide{
        .modal__content{
            opacity: 0;
            transform: translate(-50%, -50%) scale(.8);
            transition: $transition;
        }
    }
}

@mixin modelShow($show){
    @if $show == show{
        opacity: 1;
        visibility: visible;
    }
    @if $show == hide{
        opacity: 0;
        visibility: hidden;
    }
}

// .modalOpen{
//     &-enter{
//         @include modelShow(hide);
//         @include modelContent(hide);
//         transition: $transition2;
//         // animation:$animate;
//         &-done{
//             @include modelShow(show);
//             @include modelContent(show);
//         }
//         &-active {
//             @include modelShow(show);
//             @include modelContent(show);
//             transition: $transition2;
//         }
//     }
//     &-exit{
//         @include modelShow(show);
//         @include modelContent(show);
//         &-done{
//             @include modelShow(hide);
//             @include modelContent(hide);
//             transition: $transition2;
//         }
//         &-active {
//             @include modelShow(hide);
//             @include modelContent(hide);
//             transition: $transition2;
//         }
//     }
// }

.modalOpen{
    &-enter{
        
        &-done{
            display:block;
            animation:$animate;
            .modal__content{
                animation:$animate2;
            }
        }
        &-active {
            animation:$animate;
            .modal__content{
                animation:$animate2;
            }
        }
    }
    &-exit{
        animation:$animate3;
        .modal__content{
            animation:$animate4;
        }
        &-done{


        }
        &-active {

        }
    }
}