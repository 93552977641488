.toast {
  position: fixed;
  display: block;
  padding: 0.5rem 0.75rem;
  font-size: 1.1rem;
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #fff;
  border-radius: 3px;
  background: #6d6d6d;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 121212121212;

  &-container {
    position: fixed;
    display: flex;
    flex-direction: column;

    .toast {
      margin-bottom: 10px;
      position: unset !important;
      top: unset !important;
      bottom: unset !important;
      left: unset !important;
      right: unset !important;
    }
    &.bottom-left {
      bottom: 2rem;
      left: 2rem;
    }
    &.bottom-center {
      bottom: 2rem;
      left: 50%;
    }
    &.bottom-right {
      bottom: 2rem;
      right: 2rem;
    }

    &.top-left {
      top: 2rem;
      left: 2rem;
    }
    &.top-center {
      top: 2rem;
      left: 50%;
    }
    &.top-right {
      top: 2rem;
      right: 2rem;
    }
  }

  &.rounded {
    border-radius: 10rem;
  }

  &.primary {
    background-color: #{$rigo-primary-color};
  }
  &.success {
    background: #{$rigo-success-color};
  }
  &.info {
    background: #{$rigo-highlight-color};
  }
  &.warning {
    background: #{$rigo-warning-color};
  }
  &.danger {
    background: #{$rigo-danger-color};
  }

  &.bottom-left {
    bottom: 2rem;
    left: 2rem;
  }
  &.bottom-center {
    bottom: 2rem;
    left: 50%;
  }
  &.bottom-right {
    bottom: 2rem;
    right: 2rem;
  }

  &.top-left {
    top: 2rem;
    left: 2rem;
  }
  &.top-center {
    top: 2rem;
    left: 50%;
  }
  &.top-right {
    top: 2rem;
    right: 2rem;
  }
  &__body {
    margin-right: 1rem;

    ol {
      padding-left: 25px;

      li {
        margin: 6px 0;
      }
    }
  }
  &__close {
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
      color: #efefef;
    }
  }
}
