.overlay-spinner {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(70,70,70,0.4);
    top: 0;
    left: 0;
    z-index: 11111111111;
    display: none;
    
    &.show-loading{
        display: flex !important;
    }
    .spinner{
        display: flex;
        flex-direction: row;
    }
    .status{
        display: block;
        font-size: 20px;
        font-weight: 500;
        color:#4c4c4c;
        text-rendering: optimizeLegibility;
        h1 {
            position: relative;
            color: rgba(0, 0, 0, .3);
            margin-top: 10px;
            font-size: 25px;
            text-transform: capitalize;
        }
        h1:before {
            content: attr(data-text);
            position: absolute;
            overflow: hidden;
            max-width: 7em;
            white-space: nowrap;
            color: #{$rigo-white-color};
            animation: loading 8s linear alternate;
        }
        @keyframes loading {
            0% {
                max-width: 0;
            }
        }
    }
    .spin {
        width: 18px;
        height: 18px;
        background-color: #4c4c4c;

        border-radius: 100%;
        display: inline-block;
        animation: spinner-bounce 1.4s infinite ease-in-out both;
    }
    .spinner-dot-1 {
        animation-delay: -0.32s;
    }
    .spinner-dot-2 {
        animation-delay: -0.16s;
    }
}

.spinner-logo{
    width: 120px;
    height: 120px;

    svg{
        width: 100%;
        height: 100%;
    }
}

@keyframes spinner-bounce {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
