.custom_popup {
  position: fixed;
  background: #eeeff0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  min-width: 100px;
  flex-direction: column;
  //   transition: all 0.2s;
  //   transform: scale(0.25);

  &-body {
    flex-grow: 1;
    ul,
    ol,
    dl {
      margin-bottom: unset;
    }
  }
  &-footer {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;

    .popup-btn {
      width: 30px;
      height: 25px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.17);
      background: #ffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      border-radius: 4px;
      color: #707070;
      margin-left: 10px;
      cursor: pointer;
    }
    .popup-add {
      &:hover {
        background-color: lighten($color-green-fade, 10);
        color: #676767;
      }
    }
    .popup-remove {
      &:hover {
        background-color: #{$color-red-fade};
        color: #676767;
      }
    }
  }
}
