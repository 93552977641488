.user {
  &-add {
    &-top {
      display: flex;
      flex-direction: row;
      &-left {
        flex: 2;
        flex-direction: column;
        margin-right: 2rem;
      }
      &-right {
        flex: 1;
      }
    }
    &-approver-ddl {
      margin: 1.5rem 0 0 -2rem;
    }
  }
}
